<template>
  <app-header/>
  <Suspense>
    <template #default>
      <app-category :id="id"/>
    </template>
    <template #fallback>
      <spinner/>
    </template>
  </Suspense>
  <app-footer/>
</template>

<script>
import AppCategory from '../../components/Categorys/AppCategory.vue'
import AppFooter from '../../components/layouts/AppFooter.vue'
import AppHeader from '../../components/layouts/AppHeader.vue'
import Spinner from '../../components/Spinners/Spinner.vue'
export default {
    name:'categorys',
    props:{
        id:String
     },
  components: { AppHeader, AppCategory, Spinner, AppFooter },

}
</script>

<style>

</style>